import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { inject } from '@angular/core';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { toObservable } from '@angular/core/rxjs-interop';
import { AppService } from '@shared/services/app.service';
import { AppRouteService } from '@shared/services/app-route.service';
import { TPageType } from '@shared/interfaces/route.interface';
import { UserStoreService } from '@shared/services/user-store.service';

export const UserGuard = (route: ActivatedRouteSnapshot, state: any) => {
    const appService: AppService = inject(AppService);
    const router = inject(Router);
    const appRouteService: AppRouteService = inject(AppRouteService);
    const userData = inject(UserStoreService);
    const currentPath = state.url.split('/')[1] as TPageType;

    return toObservable(appService.loading).pipe(
        filter(loading => !loading),
        map(loading => {
            const activate: boolean = appRouteService.canActivate(
                currentPath.split('?')[0] as TPageType,
                state.url.split('?')[0],
                userData.user(),
            );

            // if (!activate && (environment.system == 'LOCAL_DEV' || environment.system == 'DEV'))
            //     console.log('Route cannot be activated', state.url);
            if (!activate) router.navigate([appRouteService.defaultRoute]).then();

            return true;
        }),
    );
};
