import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppService } from '@shared/services/app.service';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { toObservable } from '@angular/core/rxjs-interop';
import { AppRouteService } from '@shared/services/app-route.service';
import { UserStoreService } from '@shared/services/user-store.service';

export const LoginGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const userService = inject(UserStoreService);
    const appService: AppService = inject(AppService);
    const appRouteService: AppRouteService = inject(AppRouteService);
    const router = inject(Router);

    return toObservable(appService.loading).pipe(
        filter(loading => !loading),
        map(loading => {
            if (userService.isLoggedIn()) {
                router.navigate([appRouteService.defaultRoute]).then();
                return false;
            }

            return true;
        }),
    );
};
