import { inject, Injectable } from '@angular/core';
import { UserStoreService } from '@shared/services/user-store.service';
import { Router } from '@angular/router';
import { ThemeService } from '@shared/services/theme.service';
import { AppService } from '@shared/services/app.service';
import { HttpService } from '@core/http/http.service';
import { DeviceInfoService } from '@shared/services/device-info.service';
import { AppStylesService } from '@shared/services/app-styles.service';
import { fetchAuthSession } from 'aws-amplify/auth';
import { IUserDataDto } from '@shared/interfaces/user-data.interface';
import { UserDataModel } from '@shared/models/user-data.model';
import { AppStorageService } from '@shared/services/app-storage.service';

@Injectable({
    providedIn: 'root',
})
export class AppStarterService {
    private http: HttpService = inject(HttpService);
    private userStoreService: UserStoreService = inject(UserStoreService);
    private appService: AppService = inject(AppService);
    private themeService: ThemeService = inject(ThemeService);
    private router: Router = inject(Router);
    private deviceInfoService: DeviceInfoService = inject(DeviceInfoService);
    private appStylesService: AppStylesService = inject(AppStylesService);
    private appStorageService = inject(AppStorageService);

    async initServices(): Promise<void> {
        this.themeService.fnInit();
        this.appStylesService.fnInit();
        await this.deviceInfoService.fnInit();
        if (this.deviceInfoService.isTablet()) this.appService.closeMenu();
    }

    // redirect: used after login form -> redirect to main path if no path saved
    initUser(redirect: boolean = false): Promise<boolean> {
        if (!redirect) this.appService.startLoading();

        return new Promise(resolve => {
            const initializeUser = () => {
                this.http.getUserData().subscribe({
                    next: (user: IUserDataDto) => {
                        this.userStoreService.updateUser({
                            ...new UserDataModel(user),
                            isLoggedIn: !redirect,
                            allowLanding: true,
                        });

                        if (redirect) {
                            this.router.navigate(['auth/landing']);
                        }
                        this.appService.stopLoading();

                        resolve(true);
                    },
                    error: error => {
                        this.appService.logout();
                        this.appService.stopLoading();
                        resolve(false);
                    },
                });
            };

            if (!redirect) {
                fetchAuthSession()
                    .then(session => {
                        const token = session.tokens?.accessToken.toString();

                        if (!token) {
                            this.userStoreService.clearUser();
                            this.appService.stopLoading();
                            resolve(false);
                        } else {
                            const isFromSSO: boolean =
                                this.appStorageService.getItem(this.appService.STORAGE_SSO) ??
                                false;
                            if (isFromSSO) redirect = true;
                            initializeUser();
                        }
                    })
                    .catch(error => {
                        this.userStoreService.clearUser();
                        this.appService.stopLoading();
                        resolve(false);
                    });
            } else {
                initializeUser();
            }
        });
    }
}
